<template>
  <div class="mod-home">
   <div class="box1">
     <!-- <img width="100%" height="100%" src="../../assets/img/home.jpg" /> -->
   </div>
  </div>
</template>

<script>
  export default {
     data() {
    return {};
  }
  }
</script>

<style lang="scss" scoped>
.box1 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

